import de from "./de"
import en from "./en"
import it from "./it"
import nb from "./nb"
import nl from "./nl"
import zh from "./zh"
import tr from "./tr"

export const ROBOT_VOCABULARY = {
  'nl-NL': nl,
  'en-GB': en,
  'zh-TW': zh,
  'de-DE': de,
  'nb-NO': nb,
  'it-IT': it,
  // 'tr-TR': tr
}

export type RobotVocabulary = typeof ROBOT_VOCABULARY
export type AvailableRobotLanguages = keyof RobotVocabulary