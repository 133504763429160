/**
 * @file Functions to create new steps with correct content etc.
 */

import { StepType, ScriptStoreStep } from '../types/ScriptStep'
import { ScriptStoreNode, ScriptNodeTTS, ScriptNodeReport } from '../types/ScriptNode'
import { Report } from '../types/Report'
import {
  ScriptStoreNext,
  NextMultipleChoiceStandard,
  NextMultipleChoiceCommand
} from '../types/Next'
import { greetingSettings } from './helpers'
import { v4 as uuidv4 } from 'uuid'
import { AvailableRobotLanguages, ROBOT_VOCABULARY } from '../../../../localization/vocabulary/robot'

interface Greetings {
  greet: boolean
  notify: boolean
  sayTime: boolean
}

interface CreateStepParams {
  stepType: StepType
  first: boolean
  nextStepTempId?: string
}

interface InitStepDataParams {
  parentNode?: ScriptStoreNode | undefined
  uuid?: string
}

export const sayNodeData = (
  greetings: Greetings
): Pick<ScriptNodeTTS, 'nodeType' | 'text' | 'greet' | 'notify' | 'sayTime'> & {
  tempId: string
} => ({
  tempId: uuidv4(),
  nodeType: 'say',
  text: '',
  ...greetings
})

const initStepData = ({ uuid, parentNode }: InitStepDataParams) => {
  const newScriptSteps: ScriptStoreStep[] = []
  const newScriptNodes: ScriptStoreNode[] = []
  const newScriptNext: ScriptStoreNext[] = []
  const tempId = uuid ?? uuidv4()
  const greetings = greetingSettings(parentNode)
  const createdAt = new Date().getTime()
  return {
    newScriptSteps,
    newScriptNodes,
    newScriptNext,
    tempId,
    greetings,
    createdAt
  }
}

const createSayStep = ({
  stepType,
  nextStepTempId,
  first,
  uuid,
  parentNode
}: CreateStepParams & InitStepDataParams) => {
  const {
    newScriptSteps,
    newScriptNodes,
    newScriptNext,
    tempId,
    createdAt,
    greetings
  } = initStepData({
    uuid,
    parentNode
  })
  newScriptSteps.push({ tempId, stepType, first, createdAt })
  newScriptNodes.push({ scriptStepTempId: tempId, ...sayNodeData(greetings) })
  newScriptNext.push({
    tempId: uuidv4(),
    scriptStepTempId: tempId,
    nextType: 'then',
    nextStepTempId
  })
  return { newScriptSteps, newScriptNodes, newScriptNext }
}

const createWaitStep = ({
  stepType,
  nextStepTempId,
  first,
  uuid,
  parentNode
}: CreateStepParams & InitStepDataParams) => {
  const { newScriptSteps, newScriptNodes, newScriptNext, tempId, createdAt } =
    initStepData({
      uuid,
      parentNode
    })
  const seconds = 0
  const waitTime = { seconds, minutes: 0 }
  newScriptSteps.push({ tempId, stepType, first, createdAt })
  newScriptNodes.push({
    tempId: uuidv4(),
    scriptStepTempId: tempId,
    nodeType: 'wait',
    seconds,
    waitTime
  })
  newScriptNext.push({
    tempId: uuidv4(),
    scriptStepTempId: tempId,
    nextType: 'then',
    nextStepTempId
  })
  return { newScriptSteps, newScriptNodes, newScriptNext }
}

export const createFormFields = (report: Report) => report.form.map(({ key, displayName, type, options, defaultValue, required }) => {
  return {
    key,
    displayName,
    type,
    options,
    value: defaultValue ?? '',
    required
  }
})

const createReportStep = ({
  stepType,
  nextStepTempId,
  first,
  uuid,
  parentNode,
  reports
}: CreateStepParams & InitStepDataParams & {
  reports: Report[]
}) => {
  const { newScriptSteps, newScriptNodes, newScriptNext, tempId, createdAt } =
    initStepData({
      uuid,
      parentNode
    })
  newScriptSteps.push({ tempId, stepType, first, createdAt })
  const report = reports[0]
  const newNode: ScriptNodeReport & { tempId: string} = {
    tempId: uuidv4(),
    nodeType: 'report',
    scriptStepTempId: tempId,
    reportType: report.reportType,
    formFields: createFormFields(report)
  }
  newScriptNodes.push(newNode)
  newScriptNext.push({
    tempId: uuidv4(),
    scriptStepTempId: tempId,
    nextType: 'then',
    nextStepTempId
  })
  return { newScriptSteps, newScriptNodes, newScriptNext }
}

const createClosedQuestionStep = ({
  stepType,
  nextStepTempId,
  first,
  uuid,
  parentNode
}: CreateStepParams & InitStepDataParams) => {
  const {
    newScriptSteps,
    newScriptNodes,
    newScriptNext,
    tempId,
    createdAt,
    greetings
  } = initStepData({
    uuid,
    parentNode
  })
  const answerIds = [uuidv4(), uuidv4(), uuidv4()]
  // question step
  newScriptSteps.push({ tempId, stepType, first, createdAt })
  // steps that follow the 3 possible answers
  newScriptSteps.push({ tempId: answerIds[0], stepType: 'say', first: false })
  newScriptSteps.push({ tempId: answerIds[1], stepType: 'say', first: false })
  newScriptSteps.push({ tempId: answerIds[2], stepType: 'say', first: false })
  // node that belongs to the question steps
  const questionNode = {
    tempId: uuidv4(),
    scriptStepTempId: tempId,
    nodeType: 'say',
    text: '',
    ...greetings
  } as ScriptStoreNode
  newScriptNodes.push(questionNode)
  // nodes that belong to the answer steps
  const answerGreetings = greetingSettings(questionNode)
  newScriptNodes.push({
    scriptStepTempId: answerIds[0],
    ...sayNodeData(answerGreetings)
  })
  newScriptNodes.push({
    scriptStepTempId: answerIds[1],
    ...sayNodeData(answerGreetings)
  })
  newScriptNodes.push({
    scriptStepTempId: answerIds[2],
    ...sayNodeData(answerGreetings)
  })

  newScriptNext.push(
    // nexts that belong to the question (3 possible answers)
    {
      tempId: uuidv4(),
      scriptStepTempId: tempId,
      nextType: 'closedQuestion',
      answer: 'yes',
      nextStepTempId: answerIds[0]
    },
    {
      tempId: uuidv4(),
      scriptStepTempId: tempId,
      nextType: 'closedQuestion',
      answer: 'no',
      nextStepTempId: answerIds[1]
    },
    {
      tempId: uuidv4(),
      scriptStepTempId: tempId,
      nextType: 'closedQuestion',
      answer: 'other',
      nextStepTempId: answerIds[2]
    },
    // nexts that belong to the steps after all 3 possible answers
    { tempId: uuidv4(), scriptStepTempId: answerIds[0], nextType: 'then' },
    { tempId: uuidv4(), scriptStepTempId: answerIds[1], nextType: 'then' },
    { tempId: uuidv4(), scriptStepTempId: answerIds[2], nextType: 'then' }
  )
  return { newScriptSteps, newScriptNodes, newScriptNext }
}

const createGoToStep = ({
  stepType,
  nextStepTempId,
  first,
  uuid,
  parentNode
}: CreateStepParams & InitStepDataParams) => {
  const { newScriptSteps, newScriptNodes, newScriptNext, tempId, createdAt } =
    initStepData({
      uuid,
      parentNode
    })
  newScriptSteps.push({ tempId, stepType, first, createdAt })
  newScriptNodes.push({
    tempId: uuidv4(),
    scriptStepTempId: tempId,
    nodeType: 'goto',
    maxPasses: 1
  })
  newScriptNext.push(
    {
      tempId: uuidv4(),
      scriptStepTempId: tempId,
      nextType: 'goto',
      maxReached: false,
      nextStepTempId: undefined
    },
    {
      tempId: uuidv4(),
      scriptStepTempId: tempId,
      nextType: 'goto',
      maxReached: true,
      nextStepTempId
    }
  )
  return { newScriptSteps, newScriptNodes, newScriptNext }
}

const getMultipleChoiceBranches = (lang: string) => {
  if (lang in ROBOT_VOCABULARY) {
    return ROBOT_VOCABULARY[lang as AvailableRobotLanguages].MULTIPLE_CHOICE.DEFAULT_OPTIONS;
  } else {
    return ['']
  }
}

export const getOfflineMessage = (lang: string) => {
  if (lang in ROBOT_VOCABULARY) {
    return ROBOT_VOCABULARY[lang as AvailableRobotLanguages].MULTIPLE_CHOICE.OFFLINE_MESSAGE
  } else {
    return ''
  }
}

const createMultipleChoiceStep = ({
  stepType,
  first,
  uuid,
  parentNode,
  lang
}: CreateStepParams & InitStepDataParams & { lang: string }) => {
  const {
    newScriptSteps,
    newScriptNodes,
    newScriptNext,
    tempId,
    createdAt,
    greetings
  } = initStepData({
    uuid,
    parentNode
  })
  const standardBranches = ['other', 'offline']
  const branches = getMultipleChoiceBranches(lang)
  // create ids for command branch(es) and the other and offline branch
  const branchIds = [...branches.map(() => uuidv4()), uuidv4(), uuidv4()]
  // question step
  newScriptSteps.push({ tempId, stepType, first, createdAt })
  // steps that follow the possible branches (commands..., other, offline)
  ;[...branches, ...standardBranches].forEach((_, i) => {
    newScriptSteps.push({ tempId: branchIds[i], stepType: 'say', first: false })
  })
  // node that belongs to the question steps
  const questionNode = {
    tempId: uuidv4(),
    scriptStepTempId: tempId,
    nodeType: 'say',
    text: '',
    ...greetings
  } as ScriptStoreNode
  newScriptNodes.push(questionNode)
  // nodes that belong to the answer steps
  const answerGreetings = greetingSettings(questionNode)
  const offlineMessage = getOfflineMessage(lang)
  ;[...branches, ...standardBranches].forEach((b, i) => {
    newScriptNodes.push({
      scriptStepTempId: branchIds[i],
      ...sayNodeData(answerGreetings),
      text: b === 'offline' ? offlineMessage : ''
    })
  })
  const nextOther: NextMultipleChoiceStandard & { tempId: string } = {
    tempId: uuidv4(),
    scriptStepTempId: tempId,
    nextType: 'multipleChoice',
    intentionType: 'other',
    nextStepTempId: branchIds[branches.length]
  }
  const nextOffline: NextMultipleChoiceStandard & { tempId: string } = {
    tempId: uuidv4(),
    scriptStepTempId: tempId,
    nextType: 'multipleChoice',
    intentionType: 'offline',
    nextStepTempId: branchIds[branches.length + 1]
  }
  const commandNextBase: Omit<
    NextMultipleChoiceCommand,
    'nextStepTempId' | 'primaryCommand'
  > = {
    scriptStepTempId: tempId,
    nextType: 'multipleChoice',
    intentionType: 'command',
    secondaryCommands: []
  }
  // nexts that belong to the different answers, and the steps after
  branches.forEach((b, i) => {
    newScriptNext.push(
      {
        tempId: uuidv4(),
        nextStepTempId: branchIds[i],
        ...commandNextBase,
        primaryCommand: b
      },
      {
        tempId: uuidv4(),
        scriptStepTempId: branchIds[i],
        nextType: 'then'
      }
    )
  })
  newScriptNext.push(
    // nexts that belong to other and offline
    nextOther,
    nextOffline,
    {
      tempId: uuidv4(),
      scriptStepTempId: branchIds[branches.length],
      nextType: 'then'
    },
    {
      tempId: uuidv4(),
      scriptStepTempId: branchIds[branches.length + 1],
      nextType: 'then'
    }
  )
  return { newScriptSteps, newScriptNodes, newScriptNext }
}

export const createNewStep = (
  params: CreateStepParams & InitStepDataParams & { lang: string, reports: Report[] },
) => {
  const { stepType } = params
  switch (stepType) {
    case 'say':
      return createSayStep(params)
    case 'wait':
      return createWaitStep(params)
    case 'report':
      return createReportStep(params)
    case 'closedQuestion':
      return createClosedQuestionStep(params)
    case 'goto':
      return createGoToStep(params)
    case 'multipleChoice':
      return createMultipleChoiceStep(params)
    default:
      throw new Error('invalid stepType')
  }
}
