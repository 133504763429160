import moment from 'moment';

export const shouldEditSequence = ({ time, currentTime, recurring }) => (
	moment(time).isAfter(currentTime) ? recurring : false
);

export const shouldEditSequenceWithTypeCheck = shouldEdit => ({ type, editSequence }) => (
	type === 'readonly' ? shouldEdit : editSequence
);

export const setWeekHeaders = day => [
	{ day1: moment(day).subtract(3, 'days').valueOf() },
	{ day2: moment(day).subtract(2, 'days').valueOf() },
	{ day3: moment(day).subtract(1, 'days').valueOf() },
	{ day4: moment(day).valueOf() },
	{ day5: moment(day).add(1, 'days').valueOf() },
	{ day6: moment(day).add(2, 'days').valueOf() },
	{ day7: moment(day).add(3, 'days').valueOf() }
].map((d, i) => {
	return ({
		...d, [`day${i + 1}Formatted`]: moment(d[`day${i + 1}`]).format('dd, DD MMM')
	});
});

export const setDayTasks = (tasks, now) => tasks.map(item => {
	return {
		...item,
		overflow: false,
		utcOffsetTime: moment(item.time).format('HH:mm'),
		past: moment(item.time).startOf('minute').subtract(1, 'minutes').isBefore(now),
		endTime: item.type === 'music'
			? moment(item.time)
				.add(Math.ceil(item.music.duration / 60000), 'minutes')
				.format('HH:mm')
			: null,
		durationInMinutes: item.type === 'music' && item.music.duration
			? Math.ceil(item.music.duration / 60000)
			: 0
	};
});

export const setWeekTasks = (tasks, calendarTimes, weekHeaders, now) => (
	calendarTimes.slice(1).map(time => {
		return {
			time,
			days: weekHeaders.map((e, i) => ({
				day: `day${i + 1}`,
				tasks: tasks
					.filter(v => (v.schedule.hour[0] === time.substring(0, 2) && (moment(v.time).isSame(e[`day${i + 1}`], 'day'))))
					.map(item => ({
						...item,
						past: moment(item.time)
							.startOf('minute')
							.subtract(1, 'minutes')
							.isBefore(now)
					}))
			}))
		};
	})
);

// eslint-disable-next-line consistent-return
export const calcDaysOffset = screenWidth => {
	const dayOffsets = {
		320: 2,
		568: 3,
		768: 4,
		1279: 5
	};
	const entries = Object.entries(dayOffsets);

	if (screenWidth > entries[entries.length - 1][0]) {
		return 7;
	}

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < entries.length; i++) {
		if (screenWidth <= entries[i][0]) {
			return entries[i][1];
		}
	}
};
