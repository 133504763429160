import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Backdrop from '../Backdrop/Backdrop';
import PopUpTitle from './PopUpTitle/PopUpTitle';
import PopUpText from './PopUpText/PopUpText';
import PopUpButton from './PopUpButton/PopUpButton';
import ReactDOM from 'react-dom';
import { ClickAwayListener } from '@material-ui/core';

const Context = React.createContext();

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1040;
  ${props => props.position === 'top' && css`
    @media(max-width: 768px){
      align-items: flex-start;
      padding-top: 8px;
    }
  `}
  ${props => props.position === 'bottom' && css`
    @media(max-width: 320px) and (max-height: 520px){
      align-items: flex-end;
      padding-bottom: 8px;
    }
  `}
  
`;
Wrapper.propTypes = {
  position: PropTypes.string,
  scrollEnabled: PropTypes.bool
};
Wrapper.defaultProps = {
  scrollEnabled: false
};

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 335px;
  min-height: 160px;
  max-height: 520px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 3px;
  box-sizing: border-box;
  font-family: Montserrat;
  ${props => props.scrollEnabled && css`
    overflow: auto;
  `}
  transition: transform .3s cubic-bezier(.2,.52,.48,1.57);
  @media (max-height: 520px){
    max-height: 95vh;
  }
  @media (max-width: 320px){
    width: 300px;
    padding-left:10px;
    padding-right:10px;
  }
`;
Inner.propTypes = {
  scrollEnabled: PropTypes.bool
};
Inner.defaultProps = {
  scrollEnabled: false
};


export const InnerWrapper = styled.div`
  width: 335px;
  z-index: 2;
  @media (max-width: 320px){
    width: 300px;
  }
`;

const Footer = styled.p`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
`;

const Container = styled.div`
  position: relative;
  z-index: 0;
  height: 100%;
`;

export const PopUpProvider = ({ children }) => {
  const modalRef = useRef();
  const [context, setContext] = useState();

  // make sure re-render is triggered after initial
  // render so that modalRef exists
  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <Container>
      <Context.Provider value={context}>
        {children}
      </Context.Provider>
      <div ref={modalRef} />
    </Container>
  );
};

export const PopUpWrapper = props => {
  const { onClickAway, ...wrapperProps } = props;
  return <Wrapper  {...wrapperProps}>
    <Backdrop type="dark" data-testid="backdrop" onClick={onClickAway} />
    <ClickAwayListener onClickAway={onClickAway}>
      <InnerWrapper>
        {props.additionalBlockTop}
        <Inner scrollEnabled={props.scrollEnabled}>
          {props.title && <PopUpTitle type={props.type}>{props.title}</PopUpTitle>}
          {props.text && <PopUpText type={props.type}>{props.text}</PopUpText>}
          {props.children && <div>{props.children}</div>}
          <Footer>
            {props.cancelButton && (
              <PopUpButton aria-label="Cancel PopUp" popUpType={props.type} buttonType="cancel" onClick={props.onCancel}>
                {props.cancelButton}
              </PopUpButton>
            )}
            {props.submitButton && (
              <PopUpButton aria-label="Submit PopUp" popUpType={props.type} buttonType="submit" onClick={props.onSubmit} disabled={!props.valid}>
                {props.submitButton}
              </PopUpButton>
            )}
          </Footer>
        </Inner>
        {props.additionalBlockBottom}
      </InnerWrapper>
    </ClickAwayListener>
  </Wrapper>
}

const PopUp = props => {
  const modalNode = useContext(Context);

  return modalNode
    ? ReactDOM.createPortal(
      <PopUpWrapper  {...props}>
      </PopUpWrapper>,
      modalNode
    )
    : null;
};

PopUp.propTypes = {
  type: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  position: PropTypes.string,
  additionalBlockTop: PropTypes.element,
  additionalBlockBottom: PropTypes.element,
  cancelButton: PropTypes.string,
  submitButton: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  valid: PropTypes.bool,
  scrollEnabled: PropTypes.bool,
  onClickAway: PropTypes.func
};

PopUp.defaultProps = {
  valid: true,
  scrollEnabled: false,
  onClickAway: () => {}
}

export default PopUp;
