
/**
 * @file validator functions
 */

import 'reflect-metadata'
import { plainToClass } from 'class-transformer'
import { validateSync, ValidationError } from 'class-validator'
import { NextClosedQuestion, NextThen, BaseNext, NextGoTo, ScriptNext, ScriptStoreNext } from './types/Next'
import { ScriptNodeTTS, ScriptNodeWait, BaseScriptNode, ScriptNodeGoTo, ScriptNodeReport } from './types/ScriptNode'
import { ScriptStepDTO } from './types/ScriptStep'
import { FullScriptPut, FullScriptState } from './types/ScriptVersion'
import { NextMultipleChoiceCommand, NextMultipleChoiceStandard } from './types/Next'
import { FormField } from './types/FormField'
import { validateScriptVariables } from './helpers/scriptVariables'

const convertValidationError = (errors: ValidationError[]): string => {
    return 'Validation failed: ' + errors.map((error: ValidationError) => {
        if (error.children && error.children.length) {
            return convertValidationError(error.children)
        } else {
            return error.constraints ? Object.values(error.constraints).join(', ') : ''
        }
    }).join(', ')
}

export const validateObj = <T>(obj: any, type: any): T => {
    const classObj: any = plainToClass(type, obj)
    const validationErrors = validateSync(classObj, { forbidNonWhitelisted: true })
    if (validationErrors.length) {
        const message = convertValidationError(validationErrors)
        throw Error(message)
    }
    return classObj as T
}

export const validateFormFields = (fields: FormField[]) => {
    const invalid = fields.filter(f =>  f.required == true && !f.value)
    if (invalid.length) {
        throw Error(`Invalid form Fields: ${JSON.stringify(invalid, undefined, 3)}`)
    }
}

export const validateScriptNode = (n: ScriptNodeTTS | ScriptNodeWait | ScriptNodeGoTo | ScriptNodeReport, capabilities: string[]) => {
    const type = n.nodeType
    switch (type) {
        case 'say':
            validateScriptVariables((n as ScriptNodeTTS).text, capabilities)
            return validateObj<ScriptNodeTTS>(n, ScriptNodeTTS)
        case 'goto':
            return validateObj<ScriptNodeGoTo>(n, ScriptNodeGoTo)
        case 'report':
            validateFormFields((n as ScriptNodeReport).formFields)
            return validateObj<ScriptNodeReport>(n, ScriptNodeReport)
        case 'wait':
            if ((n as ScriptNodeWait).waitTime?.seconds && (n as ScriptNodeWait).waitTime!.seconds! > 59) {
                throw Error('Invalid seconds')
            }
            try {
                return validateObj<ScriptNodeWait>(n, ScriptNodeWait)
            } catch (e) {
                throw e
            }

        default:
            // validator should already throw because type is unknown
            validateObj<BaseScriptNode>(n, BaseScriptNode)
            throw Error('Wrong node type')
    }
}

export const validateScriptNodes = (nodes: (ScriptNodeTTS | ScriptNodeWait | ScriptNodeGoTo | ScriptNodeReport)[], capabilities: string[]) => {
    return nodes.map(n => validateScriptNode(n, capabilities))
}

export const validateScriptNextSingle = (n: ScriptNext | ScriptStoreNext) => {
    const type = n.nextType
    switch (type) {
        case 'goto':
            if ((n as NextGoTo).maxReached === false) {
                maybeThrow(!!n.nextStepTempId, 'Missing goto step')
            }
            return validateObj<NextGoTo>(n, NextGoTo)
        case 'then':
            return validateObj<NextThen>(n, NextThen)
        case 'closedQuestion':
            return validateObj<NextClosedQuestion>(n, NextClosedQuestion)
        case 'multipleChoice':
            if ((n as NextMultipleChoiceCommand).intentionType === 'command') {
                const similarCommands = (n as NextMultipleChoiceCommand).similarCommands
                const similar = similarCommands
                    && Object.values(similarCommands).some(v => {
                        return v?.commands?.length && v?.commands?.length > 0
                    })
                if(similar) {
                    throw Error('Command has similar commands')
                }
                return validateObj<NextMultipleChoiceCommand>(n, NextMultipleChoiceCommand)
            } else {
                return validateObj<NextMultipleChoiceStandard>(n, NextMultipleChoiceStandard)
            }
        default:
            // validator should already throw because type is unknown
            validateObj<BaseNext>(n, BaseNext)
            throw Error('Wrong node type')
    }
}

export const validateScriptNext = (next: (ScriptNext | ScriptStoreNext)[]) => {
    return next.map(validateScriptNextSingle)
}

export const validateScriptSteps = (steps: ScriptStepDTO[]) => steps.map(s => validateObj<ScriptStepDTO>(s, ScriptStepDTO))

const maybeThrow = (condition: boolean, message: string) => {
    if (!condition) {
        throw new Error(message)
    }
}

const checkNextStepReferences = (body: FullScriptPut) => {
    // all step ids should be valid references
    const allNextHaveMatchingSteps = body.scriptNext.every(n => {
        const scriptStepExists = body.scriptSteps.find(s => s.tempId === n.scriptStepTempId) !== undefined
        const scriptNextStepExists = body.scriptSteps.find(s => !n.nextStepTempId || (s.tempId === n.nextStepTempId && n.nextStepTempId)) !== undefined
        return scriptStepExists && scriptNextStepExists

    })
    // all steps should have a corresponding next object
    const allStepsHaveNext = body.scriptSteps.every(s => body.scriptNext.find(n => s.tempId === n.scriptStepTempId && (n as NextGoTo).maxReached !== false) !== undefined)

    return allNextHaveMatchingSteps && allStepsHaveNext
}

const checkNodesStepReferences = (body: FullScriptPut) => {
    // all step ids should be valid references
    const allNodesHaveMatchingStep = body.scriptNodes.every(n => body.scriptSteps.find(s => s.tempId === n.scriptStepTempId) !== undefined)
    // all steps should have a corresponding node object
    const allStepsHaveMatchingNode = body.scriptSteps.every(s => body.scriptNodes.find(n => s.tempId === n.scriptStepTempId) !== undefined)
    return allNodesHaveMatchingStep && allStepsHaveMatchingNode
}

const checkQuestionNext = (next: NextClosedQuestion[]) => {
    return next.length === 3 &&
        next.every(n => validateObj<NextClosedQuestion>(n, NextClosedQuestion)) &&
        (next[0] as NextClosedQuestion).answer === 'yes' &&
        (next[1] as NextClosedQuestion).answer === 'no' &&
        (next[2] as NextClosedQuestion).answer === 'other'
}

export const checkStepsWithNodesAndNextTypes = (body: FullScriptPut) => {
    if (body.scriptSteps.filter(s => s.first).length !== 1) {
        throw Error('Steps should contain a single first step')
    }
    if (body.scriptSteps.length !== body.scriptNodes.length) {
        throw Error('Steps/nodes array length mismatch')
    }

    if (!checkNextStepReferences(body)) {
        throw Error('Next references non existing step')
    }
    if (!checkNodesStepReferences(body)) {
        throw Error('Node references non existing step')
    }
    // all steps that are not the first step of a script should be referenced by a scriptnext as the next step
    const unreacheableStep = body.scriptSteps.filter(s => !s.first).find(s => (body.scriptNext.filter(n => (n as NextGoTo).maxReached !== false).find(n => s.tempId === n.nextStepTempId) === undefined))
    if (unreacheableStep) {
        throw Error(`Step ${unreacheableStep.tempId} is unreacheable`)
    }
    body.scriptSteps.forEach((step) => {
        const nodeTypeError = 'Invalid nodes for step: ' + JSON.stringify(step)
        const nextTypeError = 'Invalid next for step: ' + JSON.stringify(step)
        const nodes = body.scriptNodes.filter(node => node.scriptStepTempId === step.tempId)
        const next = body.scriptNext.filter(s => s.scriptStepTempId === step.tempId)
        switch (step.stepType) {
            case 'wait':
                maybeThrow(nodes.length === 1 && nodes[0].nodeType === 'wait', nodeTypeError)
                maybeThrow(next.length === 1 && next[0].nextType === 'then', nextTypeError)
                break
            case 'say':
                maybeThrow(nodes.length === 1 && nodes[0].nodeType === 'say', nodeTypeError)
                maybeThrow(next.length === 1 && next[0].nextType === 'then', nextTypeError)
                break
            case 'goto':
                const gotoNexts = next as NextGoTo[]
                maybeThrow(nodes.length === 1 && nodes[0].nodeType === 'goto', nodeTypeError)
                maybeThrow(gotoNexts.length === 2
                    && gotoNexts.every(n => n.nextType === 'goto')
                    && !!gotoNexts.find(n => n.maxReached === false)
                    && !!gotoNexts.find(n => n.maxReached === true),
                    nextTypeError)
                break
            case 'closedQuestion':
                maybeThrow(nodes.length === 1 && nodes[0].nodeType === 'say', nodeTypeError)
                maybeThrow(checkQuestionNext(next as NextClosedQuestion[]), nextTypeError)
                break
            default:
                break
        }
    })
}

export const hasDuplicates = (array: any[]) => {
    return (new Set(array)).size !== array.length
}

export const checkScript = (fullScriptPut: FullScriptPut, capabilities: string[]) => {
    const fullScript = validateObj<FullScriptPut>(fullScriptPut, FullScriptPut)
    // Nested array validation seems to fail so they are validated separately
    fullScript.scriptNodes = validateScriptNodes(fullScript.scriptNodes, capabilities)
    fullScript.scriptNext = validateScriptNext(fullScript.scriptNext)
    fullScript.scriptSteps = validateScriptSteps(fullScript.scriptSteps)
    if (hasDuplicates(fullScript.scriptSteps.map(s => s.tempId))) {
        throw new Error('Duplicate tempIds')
    }
    checkStepsWithNodesAndNextTypes(fullScript)
    return fullScript as FullScriptState
}

export const stripIds = (input: any, includes: string = 'id') => {
    const objClone = JSON.parse(JSON.stringify(input))
    const recursive = (obj: any) => {
        for (const prop in obj) {
            if (Array.isArray(obj[prop])) {
                obj[prop].forEach((element: any) => {
                    if (Object.keys(element)) {
                        recursive(element)
                    }
                })
            }
            if (prop.toLowerCase().includes(includes.toLowerCase())) {
                delete obj[prop]
            } else if (typeof obj[prop] === 'object') {
                recursive(obj[prop])
            }
        }
        return objClone
    }
    return recursive(objClone)
}