import { padNumber } from '../../../common/utils'
import { getDateAndTimeConstants } from '../../../common/constants'
import moment from 'moment'
import { Schedule } from '../redux/types/Schedule'
import { getRecurringType } from '../TaskRecurring/TaskRecurringInterval/convertRecurrenceUnit'

export const setPatternRecurring = (
  pickedDate: moment.MomentInput,
  type = 'singleDay',
  v2 = false,
  schedule: Schedule | null
) => {
  const weekDaysArr = getDateAndTimeConstants('weekdaysArr')
  const currentWeekDay = weekDaysArr[moment(pickedDate).isoWeekday() - 1]
  const dayOfWeekIndex = schedule?.dayOfWeekIndex?.some(d => d !== '*') ? schedule?.dayOfWeekIndex : undefined
  const dayOfMonth = schedule?.dayOfMonth.some(d => d !== '*') ? schedule?.dayOfMonth : dayOfWeekIndex ? ['*'] : undefined
  const dayOfWeek = schedule?.dayOfWeek.some(d => d !== '*') ? schedule?.dayOfWeek : [currentWeekDay]
  const patterns: { [key: string]: Schedule } = {
    singleDay: {
      minute: [moment(pickedDate).format('mm')],
      hour: [moment(pickedDate).format('HH')],
      month: ['*'],
      dayOfMonth: ['*'],
      dayOfWeek: ['*'],
      startTime: moment(pickedDate).startOf('day').format(),
      endTime: moment(pickedDate).endOf('day').format(),
      recurrenceType: undefined,
      recurrenceInterval: undefined
    },
    everyYear: {
      minute: [moment(pickedDate).format('mm')],
      hour: [moment(pickedDate).format('HH')],
      month: [moment(pickedDate).format('MM')],
      dayOfMonth: dayOfMonth && dayOfWeekIndex ? dayOfMonth : [padNumber(moment(pickedDate).date(), 2)],
      dayOfWeek: dayOfWeekIndex ? dayOfWeek : ['*'],
      dayOfWeekIndex: dayOfWeekIndex ? dayOfWeekIndex : ['*' as '*'],
      startTime: moment(pickedDate).startOf('day').format(),
      endTime: null,
      recurrenceType: 'YEARLY',
      recurrenceInterval: schedule?.recurrenceInterval ?? undefined
    },
    everyDay: {
      minute: [moment(pickedDate).format('mm')],
      hour: [moment(pickedDate).format('HH')],
      month: ['*'],
      dayOfMonth: ['*'],
      dayOfWeek: ['*'],
      startTime: moment(pickedDate).startOf('day').format(),
      endTime: null,
      recurrenceType: schedule?.recurrenceInterval && schedule?.recurrenceInterval > 1 ? 'DAILY' : 'CRON',
      recurrenceInterval: schedule?.recurrenceInterval ?? undefined
    },
    everyWeek: {
      minute: [moment(pickedDate).format('mm')],
      hour: [moment(pickedDate).format('HH')],
      month: ['*'],
      dayOfMonth: ['*'],
      dayOfWeek: dayOfWeek,
      startTime: moment(pickedDate).startOf('day').format(),
      endTime: null,
      recurrenceType: schedule?.recurrenceInterval && schedule?.recurrenceInterval > 1 ? 'WEEKLY' : 'CRON',
      recurrenceInterval: schedule?.recurrenceInterval ?? undefined
    },
    everyMonth: {
      minute: [moment(pickedDate).format('mm')],
      hour: [moment(pickedDate).format('HH')],
      month: ['*'],
      dayOfMonth: dayOfMonth && dayOfWeekIndex ? dayOfMonth : [padNumber(moment(pickedDate).date(), 2)],
      dayOfWeek: dayOfWeekIndex ? dayOfWeek : ['*'],
      dayOfWeekIndex: dayOfWeekIndex ? dayOfWeekIndex : ['*' as '*'],
      startTime: moment(pickedDate).startOf('day').format(),
      endTime: null,
      recurrenceType: 'MONTHLY',
      recurrenceInterval: schedule?.recurrenceInterval ?? undefined
    },
    everyWorkingDay: {
      minute: [moment(pickedDate).format('mm')],
      hour: [moment(pickedDate).format('HH')],
      month: ['*'],
      dayOfMonth: ['*'],
      dayOfWeek: ['mon', 'tue', 'wed', 'thu', 'fri'],
      startTime: moment(pickedDate).startOf('day').format(),
      endTime: null,
      recurrenceType: schedule?.recurrenceInterval && schedule?.recurrenceInterval > 1 ? 'WEEKLY' : 'CRON',
      recurrenceInterval: schedule?.recurrenceInterval ?? undefined
    }
  }
  return patterns[type as keyof typeof patterns] || patterns.singleDay
}

export const recognizeRecurringPattern = (schedule: Schedule) => {
  if (schedule.endTime && moment(schedule.endTime).isSame(moment(schedule.startTime), 'day')) {
    return 'singleDay'
  }
  if (schedule.recurrenceType === 'MONTHLY' || schedule.recurrenceType === 'YEARLY') {
    const type = getRecurringType(schedule.recurrenceType)
    return type
  }
  if (schedule.dayOfWeek[0] !== '*') {
    switch (schedule.dayOfWeek.length) {
      case 1:
        return 'everyWeek'
      case 5:
        return schedule.dayOfWeek.every((day: string) => ['mon', 'tue', 'wed', 'thu', 'fri'].includes(day))
          ? 'everyWorkingDay'
          : null
      case 7:
        return 'everyDay'
      default:
        return null
    }
  } else {
    return schedule.dayOfMonth[0] !== '*' ? 'everyYear' : 'everyDay'
  }
}

export const isTaskInPast = (
  currentTime: moment.Moment,
  pickedDate: moment.MomentInput,
  schedulePatternType: string | null
) => {
  const timeToCompare = currentTime.clone().add(2, 'minute').endOf('minute')

  return schedulePatternType === 'singleDay' ? timeToCompare.isSameOrAfter(moment(pickedDate)) : false
}

export const getWeekday = (pickedDate: moment.MomentInput) => {
  const weekDaysArr = getDateAndTimeConstants('weekdaysArr')
  const pickedIsoWeekDay = moment(pickedDate).isoWeekday()
  return weekDaysArr[pickedIsoWeekDay - 1]
}

export const adjustScheduleStartTime = (
  schedule: Schedule | null,
  currentTime: moment.MomentInput,
  pickedDate: moment.MomentInput
) => {
  if (!schedule) {
    return schedule
  }
  const weekDaysArr = getDateAndTimeConstants('weekdaysArr')
  if (
    (schedule.endTime && moment(schedule.endTime).isSame(moment(schedule.startTime), 'day')) ||
    moment(currentTime).isBefore(moment(pickedDate))
  ) {
    return schedule
  }
  if (schedule.dayOfWeek[0] !== '*') {
    const executionIsoWeekDays = schedule.dayOfWeek.map((day: any) => weekDaysArr.findIndex((e: any) => e === day) + 1)
    const firstExecutionIsoWeekDay = moment(pickedDate).isoWeekday()
    const nextExecDayThisWeek = executionIsoWeekDays.find((day: number) => day > firstExecutionIsoWeekDay)
    const startTime = nextExecDayThisWeek
      ? moment().isoWeekday(nextExecDayThisWeek).startOf('day').format()
      : moment().add(1, 'weeks').isoWeekday(executionIsoWeekDays[0]).startOf('day').format()

    return { ...schedule, startTime }
  }
  if (schedule.month[0] !== '*' && schedule.dayOfMonth[0] !== '*') {
    const startTime = moment()
      .add(1, 'years')
      .set({ month: parseInt(schedule.month[0], 10) - 1, date: parseInt(schedule.dayOfMonth[0], 10) })
      .startOf('day')
      .format()

    return { ...schedule, startTime }
  }
  if (schedule.dayOfMonth[0] === '*' && schedule.dayOfWeek[0] === '*') {
    const startTime = moment().add(1, 'days').startOf('day').format()

    return { ...schedule, startTime }
  }

  return schedule
}

export const editScheduleDate = ({
  pickedDate,
  schedule
}: {
  pickedDate: string | null
  schedule: Schedule | null
}) => {
  if (!schedule) return
  const hasRecurring = (!schedule.endTime || !moment(schedule.endTime).isSame(moment(schedule.startTime), 'day')) || (schedule.recurrenceType && schedule.recurrenceType !== 'CRON')
  const minute = moment(pickedDate).format('mm')
  const hour = moment(pickedDate).format('HH')
  const scheduleNew: Schedule = {
    minute: [minute],
    hour: [hour],
    month: schedule.month ? schedule.month : ['*'],
    dayOfMonth: hasRecurring ? schedule.dayOfMonth : [padNumber(moment(pickedDate).date(), 2)],
    dayOfWeekIndex: schedule.dayOfWeekIndex,
    dayOfWeek: hasRecurring ? schedule.dayOfWeek : ['*'],
    startTime: moment(pickedDate).startOf('day').format(),
    endTime: hasRecurring ? schedule.endTime : moment(pickedDate).endOf('day').format(),
    recurrenceInterval: schedule.recurrenceInterval,
    recurrenceType: schedule.recurrenceType
  }

  return { pickedDate, schedule: scheduleNew }
}

export const adjustScheduleEndTime = ({ pickedDate, schedule }: { pickedDate: string | null; schedule: Schedule }) => {
  let endTimeReset = false
  const newSchedule = { ...schedule }
  if (moment(newSchedule.endTime).isBefore(moment(pickedDate))) {
    newSchedule.endTime = moment(pickedDate).add(7, 'days').endOf('day').format()
    endTimeReset = true
  }
  return { schedule: newSchedule, endTimeReset }
}

export const compose =
  (...fns: any) =>
  (x: any) =>
    fns.reduceRight((v: any, f: any) => f(v), x) // broke without this function
