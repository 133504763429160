import { ScriptNodeWait, ScriptStoreNode } from './../types/ScriptNode'
import {
  Command,
  DBNode,
  NodeType,
  ScriptTree,
  StoreNode,
  ValidAnswerKey,
  ValidChildKey
} from './../types/DeprecatedScript'
import { ScriptStepDTO } from './../types/ScriptStep'
import { v4 as uuidv4 } from 'uuid'
import { ScriptStoreNext, NextGoTo } from './../types/Next'
import {
  FullScriptGet,
  FullScriptPut,
  FullScriptState,
  ScriptVersion
} from './../types/ScriptVersion'
import { sanitizeHTML } from './uiHelpers'
import { convertQuestions } from './convertQuestions'
import {
  stepsSelectorSimple,
  nodesSelector,
  nextSelector
} from '../redux/selectors'
import { ScriptState } from '../redux/ScriptState'
import { Report } from '../types/Report'
import { FormField } from '../types/FormField'

/**
 * @file This file contains some constants and functions that are used to manipulate state
 * and keep reducer and select functions in reducer.ts more legible.
 */

export const validChildKeys: {
  [key in ValidChildKey]: true
} = {
  then: true,
  yes: true,
  no: true,
  other: true
}

export const validAnswerKeys: {
  [key in ValidAnswerKey]: true
} = {
  yes: true,
  no: true,
  other: true
}

export const getNode = ({
  command,
  isAnswerResponse,
  parentId
}: {
  command: Command
  isAnswerResponse?: boolean
  parentId?: string
}): DBNode => {
  const keys = Object.keys(command) as NodeType[]
  const node = command[keys[0]] as DBNode
  const nodeWithType = { ...node, type: keys[0], isAnswerResponse, parentId }
  return nodeWithType
}

export const getWaitTime = (originalSeconds?: number) => {
  const minutes = originalSeconds && Math.floor(originalSeconds / 60)
  const seconds = originalSeconds && originalSeconds % 60
  return originalSeconds ? { minutes, seconds } : undefined
}

export const convertToStoreNode = (dbNode: DBNode): StoreNode => {
  const waitTime = getWaitTime(dbNode.seconds)
  const storeNode = { ...dbNode, waitTime } as any
  Object.keys(validChildKeys).forEach((key: string) => {
    const node = dbNode[key as ValidChildKey]
    const child = node ? getNode({ command: node }).uuid : undefined
    storeNode[key as ValidChildKey] = child
  })
  return storeNode
}

export const getChildren = (tree?: ScriptTree) => {
  let children: string[] = []
  const countChildren = (subTree?: ScriptTree) => {
    if (!subTree) {
      return
    }
    Object.keys(validChildKeys).forEach((key: string) => {
      let childTree = subTree[key as ValidChildKey]
      if (childTree) {
        children.push(childTree.uuid)
        countChildren(childTree)
      }
    })
  }
  countChildren(tree)
  return children
}

export const hasText = (text?: string) => {
  const textWithoutEmptySpace = text
    ? text
        .replace(/<br>/g, '')
        .replace(/&nbsp;/g, '')
        .replace(/ /g, '')
        .replace(/&hairsp;/g, '')
        .replace(/\s/g, '')
    : ''
  return !!text && textWithoutEmptySpace.length > 0
}

export const mapReportNodes = (scriptNodes: ScriptStoreNode[], reports: Report[]) => {
  const scriptNodesNew = scriptNodes.map(n => {
    if(n.nodeType !== 'report') {
      return n
    } else {
      const report = reports.find(r => r.reportType === n.reportType)
      const formFields = report?.form.map(({ key, displayName, required, type, options }) => {
        const reportField = n.formFields.find(({ key: nodeFieldKey }) => nodeFieldKey === key)
        return {
          key,
          type,
          displayName,
          required,
          options,
          value: reportField?.value ?? ''
        }
      }) ?? []
      return {
        ...n,
        formFields
      }
    }
  })
  return scriptNodesNew
}

export const addTempIds = (fullScript: FullScriptGet) => {
  const scriptSteps = fullScript.scriptSteps.map(s => ({
    ...s,
    tempId: uuidv4()
  }))
  const scriptNodes = fullScript.scriptNodes.map(n => ({
    ...n,
    tempId: uuidv4(),
    scriptStepTempId: scriptSteps.find(s => s.id === n.scriptStepId)!.tempId
  }))
  const scriptNext = fullScript.scriptNext.map(nxt => ({
    ...nxt,
    tempId: uuidv4(),
    nextStepTempId: scriptSteps.find(s => s.id === nxt.nextStepId)?.tempId,
    scriptStepTempId: scriptSteps.find(s => s.id === nxt.scriptStepId)?.tempId
  }))
  return {
    script: fullScript.script,
    scriptSteps,
    scriptNodes,
    scriptNext
  }
}

export const addWaitTime = (script: FullScriptGet) => {
  return {
    ...script,
    scriptNodes: script.scriptNodes.map(n =>
      n.nodeType === 'wait'
        ? {
            ...n,
            waitTime: getWaitTime(n.seconds)
          }
        : n
    )
  }
}

export const prepareScriptState = (
  script: FullScriptGet,
  reports: Report[],
  capabilities?: string[],
  templateGroup?: string
) => {
  script.scriptNodes = mapReportNodes(script.scriptNodes as ScriptStoreNode[], reports)
  script = addTempIds(script)
  script = addWaitTime(script)
  if (capabilities?.includes('multipleChoice-v1')) {
    if(!script.script.scriptLanguage) {
      script.script.scriptLanguage = 'nl-NL'
    }
    script = convertQuestions(script as FullScriptState)
  }
  if(templateGroup) {
    script.script.scriptTemplateId = script.script.id
    script.script.id = undefined
    script.script.scriptReferenceId = undefined
  }
  return script as FullScriptState
}

export const getFullScriptPut = (
  state: ScriptState,
  newScript?: boolean
): FullScriptPut => {
  let script = { ...state.script }
  if (newScript) {
    const { scriptReferenceId, ...scriptWithoutRef } = state.script
    script = scriptWithoutRef
  }
  const scriptSteps = stepsSelectorSimple(state)
  const scriptNodes = nodesSelector(state)
  const scriptNext = nextSelector(state)
  const fullScript = prepareForPut({
    script,
    scriptSteps,
    scriptNodes,
    scriptNext
  })
  return fullScript
}

export const greetingSettings = (storeParent?: ScriptStoreNode) => {
  const parent: ScriptStoreNode =
    storeParent && JSON.parse(JSON.stringify(storeParent))
  const minutes = (parent as ScriptNodeWait)?.waitTime?.minutes || 0
  const seconds = (parent as ScriptNodeWait)?.waitTime?.seconds || 0
  const waitTime = minutes * 60 + seconds

  // greet when first node or parent is wait of longer than 5 minutes
  const greet = !parent || waitTime >= 5 * 60 ? true : false
  // say time when first node or parent is wait of longer than 5 minutes
  const sayTime = !parent || waitTime >= 5 * 60 ? true : false
  // jingle when first node or after wait of 10 or higher
  const notify = !parent || waitTime > 9 ? true : false
  return {
    greet,
    notify,
    sayTime
  }
}

export const getStepsToRemove = (
  scriptNodes: ScriptStoreNode[],
  scriptNext: ScriptStoreNext[],
  firstStepTempId: string
) => {
  let stepTempIds: string[] = []
  let nodeTempIds: string[] = []
  let nextTempIds: string[] = []
  const nodes: ScriptStoreNode[] = JSON.parse(JSON.stringify(scriptNodes))
  const next: ScriptStoreNext[] = JSON.parse(JSON.stringify(scriptNext))
  const recursive = (stepTempId: string) => {
    const matchingNodes = nodes.filter(n => n.scriptStepTempId === stepTempId)
    const matchingNext = next.filter(nxt => nxt.scriptStepTempId === stepTempId)
    stepTempIds = [...stepTempIds, ...[stepTempId]]
    nodeTempIds = [...nodeTempIds, ...matchingNodes.map(n => n?.tempId)]
    nextTempIds = [...nextTempIds, ...matchingNext.map(nxt => nxt?.tempId)]
    matchingNext.forEach(nxt => {
      // remove next step if it's not a goto step
      if (nxt.nextStepTempId && (nxt as NextGoTo).maxReached !== false) {
        recursive(nxt.nextStepTempId)
      }
    })
  }
  recursive(firstStepTempId)
  return { stepTempIds, nodeTempIds, nextTempIds }
}

const removeEmptyCommands = (commands?: string[]) => {
  return commands ? commands.filter(c => c.length > 0) : undefined
}

export const prepareForPut = ({
  scriptSteps,
  scriptNodes,
  scriptNext,
  script
}: {
  scriptSteps: ScriptStepDTO[]
  scriptNodes: ScriptStoreNode[]
  scriptNext: ScriptStoreNext[]
  script: ScriptVersion
}) => {
  const fullScript = {
    script: {
      scriptName: script.scriptName,
      scriptCategory: script.scriptCategory,
      scriptReferenceId: script.scriptReferenceId,
      scriptLanguage: script.scriptLanguage,
      scriptTemplateId: script.scriptTemplateId
    },
    scriptSteps: scriptSteps.map(step => ({
      tempId: step.tempId,
      stepType: step.stepType,
      first: step.first
    })),
    scriptNodes: scriptNodes.map((node: any) => ({
      scriptStepTempId: node.scriptStepTempId,
      nodeType: node.nodeType,
      text: sanitizeHTML(node.text),
      seconds: node.seconds,
      notify: node.notify,
      maxPasses: node.maxPasses,
      greet: node.greet,
      sayTime: node.sayTime,
      reportType: node.reportType,
      formFields: node.formFields?.map(({ key, value }: FormField) => {
        return {
          key,
          value: !!value ? value : null
        }
      })
    })),
    scriptNext: scriptNext.map((next: any) => ({
      scriptStepTempId: next.scriptStepTempId,
      nextType: next.nextType,
      answer: next.answer,
      nextStepTempId: next.nextStepTempId,
      maxReached: next.maxReached,
      intentionType: next.intentionType,
      primaryCommand: next.primaryCommand,
      secondaryCommands: removeEmptyCommands(next.secondaryCommands)
    }))
  }
  return fullScript
}
